import React, { useState, useEffect } from 'react'
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Components
import Pagination from './Pagination/pagination'

// images
import NoResultFound from '../images/uploads/no-result-found.png'
import tvFeatureImg from "../../src/images/tv-application-feature-img.png"

const PortfolioFilterList = ({ portfolio, changeFilter, changeState }) => {

  let PageSize = 12;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageValue, setPageValue] = useState(true);
  const firstPageIndex = (currentPage - 1) * PageSize;
  const lastPageIndex = firstPageIndex + PageSize;
  const array = portfolio.slice(firstPageIndex, lastPageIndex)


  useEffect(() => {
    if (changeFilter && pageValue) {
      setCurrentPage(1)
    }
    const elements = document.querySelectorAll('.portfolio-web-app .du-showcase-feature-img');
    Array.prototype.forEach.call(elements, function (ele) {
      const h0 = ele.clientHeight;
      const h1 = ele.children[0].clientHeight;
      const styleValue = "--t:" + (h0 - h1) + "px"
      ele.style.cssText += styleValue;
    });
  })

  const changePage = (page) => {
    setCurrentPage(page)
    changeState(false)
  }

  return (
    <>
      <div className="container mx-auto">
        <div className="blog-wrap grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-35 sm:gap-x-6-6 md:gap-y-45">
          {array.length > 0 ? (
            array.map(({ node: post }, j) => {
              console.log('post', post.acfPortfolio.alt)

              return (
                post.status == 1 && (
                  <div
                    key={j}
                    className={`portfolio-app-main ${post.acfPortfolio.portfolioType === "Mobile Application"
                      ? `portfolio-mobile-app`
                      : post.acfPortfolio.portfolioType === "TV"
                        ? `portfolio-tv-app`
                        : `portfolio-web-app`
                      }`}
                  >
                    <Link to={post.slug} className="block">
                      <div className="du-device-element">
                        <div className="du-elements">
                          <span className="du-elements-speaker du-elements-dots"></span>
                          <span className="du-elements-camera du-elements-dots"></span>
                          <span className="du-elements-dots"></span>
                        </div>
                        <div className="du-elements-switch">
                          <span className="du-elements-switch-lock"></span>
                          <span className="du-elements-switch-vol1"></span>
                          <span className="du-elements-switch-vol2"></span>
                        </div>
                        <div className="du-showcase-feature-img">
                          {post.acfPortfolio.portfolioType ===
                            "Mobile Application" ? (
                            <div className="du-mobile-imgs-main">
                              {post.photosForSlider.length > 0 &&
                                post.photosForSlider.map((image, i) => (
                                  <img
                                    key={i}
                                    src={image}
                                    alt={ post.acfPortfolio.alt}
                                    className={`w-full block du-mobile-imges-main  du-mobile-img--${i + 1
                                      }`}
                                  />
                                ))}
                            </div>
                          ) : (
                            <div
                              style={{
                                height:
                                  post.featuredImage.node.localFile
                                    .childImageSharp.gatsbyImageData.height <
                                    post.featuredImage.node.localFile
                                      .childImageSharp.resize.height
                                    ? post.featuredImage.node.localFile
                                      .childImageSharp.gatsbyImageData.height
                                    : post.featuredImage.node.localFile
                                      .childImageSharp.resize.height,
                              }}
                            >
                              <GatsbyImage
                                image={getImage(
                                  post.featuredImage.node.localFile
                                )}
                                alt={post.featuredImage.node.altText}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </Link>
                    <div className="du-showcase-content">
                      <Link to={post.slug} className="block">
                        <h3 className="pt-6 pb-2 text-p2 xl:text-h3 font-poppings-bold text-term-primary text-left">
                          {post.title}
                        </h3>
                      </Link>
                      <span className="text-p4 text-term-primary font-worksans-normal opacity-70" >
                        {post.portfolioTags.nodes.map(x => x.name).join(", ")}
                      </span>
                    </div>
                  </div>
                )
              )
            })
          ) : (
            <>
            
              <div>
                <img src={NoResultFound} alt="No Result Found!" />
                <h3 className="py-2 text-center text-term-primary font-poppings-bold text-h3">
                  No results found
                </h3>
                <p className="text-term-primary font-worksans-normal  text-p4 sm:text-p2 text-center">
                  It seems we can’t find any results based on your search.
                </p>
              </div>
            </>
          )}
        </div>

        {/* TV APPlication Div */}
        {/* <div className="portfolio-tv-app">
          <Link className="block">
            <div className="du-device-element">
              <div className="du-elements">
                <span className="du-elements-speaker du-elements-dots"></span>
                <span className="du-elements-camera du-elements-dots"></span>
                <span className="du-elements-dots"></span>
              </div>
              <div className="du-elements-switch">
                <span className="du-elements-switch-lock"></span>
                <span className="du-elements-switch-vol1"></span>
                <span className="du-elements-switch-vol2"></span>
              </div>
              <div className="du-showcase-feature-img">
                <img src={tvFeatureImg} alt="tvFeatureImg" />
              </div>
            </div>
          </Link>
          <div className="du-showcase-content">
            <Link className="block">
              <h3 className="pt-6 pb-4 text-p2 xl:text-h3 font-poppings-bold text-term-primary text-left">
               TV Applications
              </h3>
            </Link>
            <div className="flex flex-wrap"></div>
          </div>
        </div> */}
        {/* {hasMore && (
          <div className="mt-43 mb-50 lg:mb-0 text-center lg:mt-72 xl:mt-92">
            <a
              href={void 0}
              onClick={handleLoadMore}
              className="py-3 pl-36 pr-35 font-poppings-bold inline-block bg-celeste text-rh5 lg:text-h5 text-dark-blue shadow-1 hover:shadow-none transition-all duration-300 ease-in-out delay-0"
            >
              Load More Portfolios
            </a>
          </div>
        )} */}

        <Pagination
          className="dup-pagination-bar my-10 lg:mt-70 lg:mb-50"
          currentPage={currentPage}
          totalCount={portfolio.length}
          pageSize={PageSize}
          onPageChange={page => changePage(page)}
        />
      </div>
    </>
  )
}

export default PortfolioFilterList  