import React, { useEffect, useRef, useState } from "react"
import { Link, graphql } from 'gatsby'

// Components
import Layout from '../components/layout'
import Seo from "../components/seo"
import PortfolioSVGAnimation from "../components/Animation/portfolio-svg-animation"
import PortfolioFilterList from "../components/portfolio-filter-list"
import LiveChat from "../components/Home/livechat"


const Portfolio = ({ data,location }) => {

	console.log('data', data)

	const SEOdata = data.pageData.edges[0].node.frontmatter?.seo_metadata
	const Schemadata = data.pageData.edges[0].node.frontmatter?.Schema
	const Hreflang = data.pageData.edges[0].node.frontmatter?.Hreflang
	const ref = useRef()
	const scrollToShowcaseRef = useRef(null)
	const allPosts = data.allPost.edges
	const allIndusties = data.industies.edges
	const [isSelect, setIsSelect] = useState({
		status: false,
		id: 'all'
	})
	const [ischecked, setIsChecked] = useState(1)
	const [portfolio, setPortfolio] = useState([])
	const [changeFilter, setChangeFilter] = useState(false)
	const [lable, setLable] = useState('All')
	const [isMounted, setIsMounted] = useState(false)

	useEffect(() => {
		if (!isMounted) {
			const array = [...allPosts]
			allPosts.map(({ node: post }, i) => {
				array[i].node.status = 1
			})
			setPortfolio(array)
			setIsMounted(true)
		}
		const checkIfClickedOutside = e => {
			const { status } = isSelect
			if (status && ref.current && !ref.current.contains(e.target)) {
				setIsSelect({
					...isSelect,
					status: false
				})
			}
		}
		document.addEventListener("mousedown", checkIfClickedOutside)
		return () => {
			document.removeEventListener("mousedown", checkIfClickedOutside)
		}
	}, [isSelect])

	// method for change pagination state while change any filter option
	const changeState = (fromChild) => {
		setChangeFilter(fromChild)
		scrollToShowcaseRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
	}

	// method for transition on portfolio while change any filter
	const transitionEffect = () => {
		const divs = document.querySelectorAll('.portfolio-app-main');
		for (var i = 0; i < divs.length; i++) {
			divs[i].classList.add('active');
		}
		setTimeout(() => {
			for (var i = 0; i < divs.length; i++) {
				divs[i].classList.remove('active');
			}
		}, 1000);
	}

	// method for change **industy** filter
	const onFilterChange = (indId, lable) => {
		scrollToShowcaseRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
		transitionEffect()
		setChangeFilter(true)
		const array1 = [...allPosts]
		setLable(lable)

		if (indId == "all" && ischecked == 1) {
			allPosts.map(({ node: post }, i) => {
				array1[i].node.status = 1
			})
		} else if (indId !== "all") {
			if (ischecked == 2) {
				allPosts.map(({ node: post }, i) => {
					array1[i].node.status = 0
					post.industries.nodes.map((ind, j) => {
						if (post.acfPortfolio.portfolioType === "Website Application" && indId === ind.id) {
							array1[i].node.status = 1
						}
					})
				})
			} else if (ischecked == 3) {
				allPosts.map(({ node: post }, i) => {
					array1[i].node.status = 0
					post.industries.nodes.map((ind, j) => {
						if (post.acfPortfolio.portfolioType === "Mobile Application" && indId === ind.id) {
							array1[i].node.status = 1
						}
					})
				})
			} else if (ischecked == 4) {
				allPosts.map(({ node: post }, i) => {
					array1[i].node.status = 0
					post.industries.nodes.map((ind, j) => {
						if (post.acfPortfolio.portfolioType === "TV" && indId === ind.id) {
							array1[i].node.status = 1
						}
					})
				})
			} else {
				allPosts.map(({ node: post }, i) => {
					array1[i].node.status = 0
					post.industries.nodes.map((ind, j) => {
						if (indId === ind.id) {
							array1[i].node.status = 1
						}
					})
				})
			}
		} else if (indId == "all") {
			if (ischecked == 2) {
				allPosts.map(({ node: post }, i) => {
					array1[i].node.status = 0
					post.industries.nodes.map((ind, j) => {
						if (post.acfPortfolio.portfolioType === "Website Application") {
							array1[i].node.status = 1
						}
					})
				})
			} else if (ischecked == 3) {
				allPosts.map(({ node: post }, i) => {
					array1[i].node.status = 0
					post.industries.nodes.map((ind, j) => {
						if (post.acfPortfolio.portfolioType === "Mobile Application") {
							array1[i].node.status = 1
						}
					})
				})
			} else if (ischecked == 4) {
				allPosts.map(({ node: post }, i) => {
					array1[i].node.status = 0
					post.industries.nodes.map((ind, j) => {
						if (post.acfPortfolio.portfolioType === "TV") {
							array1[i].node.status = 1
						}
					})
				})
			}
		}
		const final = array1.filter(({ node: post }) => (post.status == 1));
		setPortfolio(final)
		setIsSelect({
			status: false,
			id: indId
		})
	}

	// method for change portfolio **view** filter
	const onViewChange = (option) => {
		scrollToShowcaseRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
		setChangeFilter(true)
		const { id } = isSelect
		const array1 = [...allPosts]
		if (option == "app") {
			if (id == 'all') {
				allPosts.map(({ node: post }, i) => {
					array1[i].node.status = 0
					if (post.acfPortfolio.portfolioType === "Mobile Application") {
						array1[i].node.status = 1
					}
				})
			} else {
				allPosts.map(({ node: post }, i) => {
					array1[i].node.status = 0
					post.industries.nodes.map((ind, j) => {
						if (post.acfPortfolio.portfolioType === "Mobile Application" && id === ind.id) {
							array1[i].node.status = 1
						}
					})
				})
			}
			setIsChecked(3)
		} else if (option == "web") {
			if (id == 'all') {
				allPosts.map(({ node: post }, i) => {
					array1[i].node.status = 0
					if (post.acfPortfolio.portfolioType === "Website Application") {
						array1[i].node.status = 1
					}
				})
			} else {
				allPosts.map(({ node: post }, i) => {
					array1[i].node.status = 0
					post.industries.nodes.map((ind, j) => {
						if (post.acfPortfolio.portfolioType === "Website Application" && id === ind.id) {
							array1[i].node.status = 1
						}
					})
				})
			}
			setIsChecked(2)
		} else if (option == "TV") {
			if (id == 'all') {
				allPosts.map(({ node: post }, i) => {
					array1[i].node.status = 0
					if (post.acfPortfolio.portfolioType === "TV") {
						array1[i].node.status = 1
					}
				})
			} else {
				allPosts.map(({ node: post }, i) => {
					array1[i].node.status = 0
					post.industries.nodes.map((ind, j) => {
						if (post.acfPortfolio.portfolioType === "TV" && id === ind.id) {
							array1[i].node.status = 1
						}
					})
				})
			}
			setIsChecked(4)
		} else {
			if (id == 'all') {
				allPosts.map(({ node: post }, i) => {
					array1[i].node.status = 1
				})
			} else {
				allPosts.map(({ node: post }, i) => {
					array1[i].node.status = 0
					post.industries.nodes.map((ind, j) => {
						if (id === ind.id) {
							array1[i].node.status = 1
						}
					})
				})
			}
			setIsChecked(1)
		}
		const final = array1.filter(({ node: post }) => (post.status == 1));
		setPortfolio(final)

		console.log('final', final)

	}

	// custom dropdown hide/show
	const showDropdownMenu = (event) => {
		event.preventDefault()
		const { status } = isSelect
		setIsSelect({
			...isSelect,
			status: !status
		})
	}
	const { status, id } = isSelect
	return (
    <Layout>
      <Seo
        title={SEOdata ? SEOdata.seo_title : "Portfolio"}
        description={SEOdata ? SEOdata.seo_description : ""}
        keywords={SEOdata ? SEOdata.seo_keyword : ""}
        image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
		single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
        currentPageUrlPath={location.pathname}
        image_format={SEOdata ? SEOdata.image_format : ""}
        image_height={SEOdata ? SEOdata.feature_image_height : ""}
        img_width={SEOdata ? SEOdata.img_width : ""}
        facebook_url={SEOdata ? SEOdata.facebook_url : ""}
        twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
        twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
        twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
        twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
        page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
        page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
        sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
        breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
        logo_schema={Schemadata ? Schemadata.logo_schema : ""}
        organization_schema={Schemadata ? Schemadata.organization_schema : ""}
        aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
        local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
        site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
        speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
        hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
        hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
        hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
        hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
      />
      <section className="mb-100">
        {/* banner content */}
        <div className="pt-60 lg:pt-20">
          <div>
            <div className="pt-38">
              <div className="du-portfolio-heading text-center w-full mx-auto">
                <h1 className="mb-0 md:mb-2-2 font-poppings-bold text-rh2 sm:text-h2 xl:text-h2 text-term-primary">
                  Welcome To <br />
                  <span className="block text-cyan"> DecodeUp Portfolio</span>
                </h1>
              </div>
              <div className="du-portfollio-banner-img ">
                <PortfolioSVGAnimation />
              </div>
            </div>
          </div>
        </div>

        {/* portfolio showcases */}
        <div className="mt-4 md:mt-0">
          <div className="container mx-auto text-center">
            <div className="mx-auto du-showcase-head">
              <h2 className="mb-2-2 text-center font-poppings-bold  text-rh1 sm:text-rh2 lg:text-h2 text-term-primary">
                Our Projects
                <span className="text-cyan"> Showcases</span>
              </h2>
              <p className="font-worksans-normal text-p4 sm:text-p2 text-term-primary text-center">
                At DecodeUp, one of the major components of our process is
                detailed case studies, conducted to understand how digital
                technology can best be leveraged to deliver powerful results.
              </p>
            </div>
          </div>
        </div>

        <div
          ref={scrollToShowcaseRef}
          className="pt-6 lg:pt-10 pb-10 lg:pb-70 du-showcase-portfolio-main"
        >
          <div className="container mx-auto">
            <div className="flex items-center flex-col ms:flex-row justify-between">
              {/* <ul className="flex items-center portfolio-tab mb-3 ms:mb-0 "> */}
                {/* Please add "Active" class while user click on different tabs. I alrady added css for it */}
                {/* <li
                  onClick={e => onViewChange("all")}
                  className={`${
                    ischecked == 1 ? "active" : ""
                  } block mr-2 px-3 sm:px-7 py-2-2 text-dark-grey font-poppings-bold text-p4 lg:text-p2 border border-dark-grey cursor-pointer`}
                >
                  All
                </li>
                <li
                  onClick={e => onViewChange("web")}
                  className={`${
                    ischecked == 2 ? "active" : ""
                  } block mx-2 px-3 sm:px-7 py-2-2 text-dark-grey font-poppings-bold text-p4 lg:text-p2 border border-dark-grey cursor-pointer`}
                >
                  Web
                </li>
                <li
                  onClick={e => onViewChange("app")}
                  className={`${
                    ischecked == 3 ? "active" : ""
                  } block mx-2 px-3 sm:px-7 py-2-2 text-dark-grey font-poppings-bold text-p4 lg:text-p2 border border-dark-grey cursor-pointer`}
                >
                  App
                </li>
                <li
                  onClick={e => onViewChange("TV")}
                  className={`${
                    ischecked == 4 ? "active" : ""
                  } block ml-2 px-3 sm:px-7 py-2-2 text-dark-grey font-poppings-bold text-p4 lg:text-p2 border border-dark-grey cursor-pointer`}
                >
                  TV
                </li> */}
              {/* </ul> */}
              {/* <div className="flex items-center md:justify-end">
                <p className="mr-4 text-term-primary font-poppings-bold text-p3  lg:text-h4">
                  Industries:
                </p>
                <div ref={ref}>
                  <div className="relative">
                    <Link
                      to="#"
                      onClick={showDropdownMenu}
                      className={`portfolio-filter-inner block pl-3 pr-6-6 py-1-2 lg:py-2-2 text-term-primary font-poppings-normal text-h4 lg:text-p2 border border-dark-grey cursor-pointer ${
                        status ? "active" : ""
                      }`}
                    >
                      <span className="text-p4 lg:text-p2 mr-2 portfolio-filter-inner-text ">
                        {lable}
                      </span>
                      <div className="portfolio-filter-inner-svg">
                        <span className="absolute right-0 top-1/2 mr-3-2 -translate-y-1/2">
                          <svg
                            width={16}
                            height={16}
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="xMinYMin meet"
                          >
                            <mask
                              id="a"
                              style={{
                                maskType: "alpha",
                              }}
                              maskUnits="userSpaceOnUse"
                              x={0}
                              y={0}
                              width={16}
                              height={16}
                            >
                              <path
                                transform="matrix(-1 0 0 1 16 0)"
                                fill="#C4C4C4"
                                d="M0 0h16v16H0z"
                              />
                            </mask>
                            <g mask="url(#a)">
                              <path
                                d="M2.333 6.333 8 12l5.667-5.667"
                                stroke="var(--bg-background-septenary)"
                              />
                            </g>
                          </svg>
                        </span>
                      </div>
                    </Link>
                    {status ? (
                      <div className="mt-1 absolute right-0  bg-background-primary-1 portfolio-filter-dropdown">
                        <ul className="pb-1">
                          <li
                            className="block text-term-primary font-poppings-normal text-p4 lg:text-p2"
                            onClick={e => onFilterChange("all", "All")}
                          >
                            <span
                              className="text-p4 lg:text-p2 px-5 py-2 w-full block whitespace-nowrap"
                              to="#"
                            >
                              All
                            </span>
                          </li>
                          {allIndusties.map(({ node: post }, i) => (
                            <li
                              key={i}
                              className="block text-term-primary font-poppings-normal text-h5 lg:text-p"
                              onClick={e => onFilterChange(post.id, post.name)}
                            >
                              <span
                                className="px-5 py-2 w-full block whitespace-nowrap"
                                to="#"
                              >
                                {post.name}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <PortfolioFilterList
          portfolio={portfolio}
          changeFilter={changeFilter}
          changeState={changeState}
        />
      </section>
      <LiveChat />
    </Layout>
  )
}

export default Portfolio

export const query = graphql`
query {
  industies: allWpIndustry(
    filter: { parentId: { eq: null }, count: { ne: 0 } }
    ) {
      edges {
        node {
          name
          id
          slug
          count
        }
      }
    }
    allPost: allWpPortfolio(sort: { order: DESC, fields: [modified] }) {
      edges {
        node {
          title
          acfPortfolio {
            portfolioType
            subTitle
			alt
          }
          photosForSlider
          portfolioTags {
            nodes {
              name
              slug
            }
          }
          industries {
            nodes {
            name
            id
            parentId
            }
          }
          slug
          featuredImage {
            node {
				altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                  resize {
                    width
                    height
                  }
                }
              }
            }
          }
        }
      }
    }
	pageData: allMarkdownRemark(
		filter: { fileAbsolutePath: { regex: "/pages/portfolio.md/" } }
	  ) {
		edges {
		  node {
			frontmatter {
			  seo_metadata {
				seo_title
				seo_keyword
				seo_description
				image_format
				feature_image_height
				img_width
				facebook_url
				twitter_page_username
				twitter_image_alt_tag
				twitter_estimated_reading_lable
				twitter_estimated_reading_time
				page_publish_date
				page_last_modified_time
				seo_Image {
				  childImageSharp {
					gatsbyImageData(layout: FIXED, width: 1200)
				  }
				}
				seo_Single_Image
			  }
			  Schema {
				sitelinks_schema
				breadcrumb_schema
				logo_schema
				organization_schema
				aggregate_rating_schema
				local_business_schema
				site_navigation_schema
				speakable_specification_schema
			  }
			  Hreflang {
				hreflang_1
				hreflang_2
				hreflang_3
				hreflang_4
			  }
			}
		  }
		}
	  }
  }
  `