import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';
const Pagination = props => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
        className
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];
    return (
      <ul
        className={classnames(
          "pagination-container flex justify-center items-center",
          {
            [className]: className,
          }
        )}
      >
        <li
          className={classnames(
            "pagination-item text-term-secondary p-2 mx-3 ",
            {
              disabled: currentPage === 1,
            }
          )}
          onClick={onPrevious}
        >
          <div className="arrow left">
            <svg
              className="transform rotate-180 stroke-current text-term-secondary"
              width="20"
              height="14"
              viewBox="0 0 20 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 7L19.356 6.99058M13.0498 1L19.7095 6.9656L13.456 13.3186" />
            </svg>
          </div>
        </li>
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <li
                key={index}
                data-id={index}
                className="pagination-item text-term-secondary p-2 dots"
              >
                &#8230;
              </li>
            )
          }

          return (
            <li
              key={index}
              className={classnames(
                "pagination-item numbers mx-3 w-8 h-8  text-term-secondary rounded-full font-worksans-normal",
                {
                  selected: pageNumber === currentPage,
                }
              )}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </li>
          )
        })}
        <li
          className={classnames("pagination-item text-term-secondary p-2  mx-3", {
            disabled: currentPage === lastPage,
          })}
          onClick={onNext}
        >
          <div className="arrow right">
            <svg
              className="stroke-current text-term-secondary"
              width="20"
              height="14"
              viewBox="0 0 20 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 7L19.356 6.99058M13.0498 1L19.7095 6.9656L13.456 13.3186" />
            </svg>
          </div>
        </li>
      </ul>
    )
};

export default Pagination;
